<template>
  <div>
<!--    <div id="首页" class="head-label">-->
<!--      <div style="text-align: center;" class="font_size_24">-->
<!--        &lt;!&ndash;        首页&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--    滚动和二维码暂时注释-->
<!--    <div  >-->
<!--      <div style="width: 20%;border-style: solid; border-width: 0px; border-color: black; float:left;" >-->
<!--        <img style="margin-left: 3%;" id="qr-code-img" src="@/assets/images/home/test.png"  alt="#">-->
<!--        <div style="display: flex;align-items: center; height: 100%;">-->
<!--          <el type="text" style="margin-left: 20px;margin-right: 20px;align-content: center;font-size: 60px; font-family: 华文行楷" class="header-title" >扫码下载</el>-->

<!--        </div>-->
<!--      </div>-->
<!--      <div style="margin-left:21%;border-style: solid; border-width: 0px; border-color: black; width: auto" >-->
<!--        <el-carousel :height="computeHeight" style="width: auto; ">-->
<!--          <el-carousel-item v-for="(item, index) in sliders" :key="index" >-->
<!--            <img :src="item.img" class="slider_img" >-->
<!--          </el-carousel-item>-->
<!--        </el-carousel>-->
<!--      </div>-->
<!--    </div>-->


    <div  style="margin: auto;width: 100%;height: 900px; ">
        <img style="width:100%; height: 900px" id="qr-code-img" src="@/assets/images/home/index/index_1.png" alt="#">
    </div>


    <!-- 手机端商品展示 -->
    <div id="个性化" class="head-label" style="margin-top: 30px">
      <div style="text-align: center;font-size: 1.5em;" class="font_size_24" >
<!--        个性化-->
      </div>
    </div>
    <!-- 盒子 -->
    <div class="border_parent">
      <el-row :gutter="3">
        <el-col :xs="15" :sm="15" :md="8">
          <div class="border">
            <div class="border_title">{{images[1].title}}</div>
            <div class="border_content_one">{{images[1].content[0]}}</div>
            <div class="border_img_parent">
              <img :src="images[1].img" class="img_border"/>
            </div>

          </div>
        </el-col>
        <el-col :xs="15" :sm="15" :md="8">
          <div class="border">
            <div class="border_title">{{images[2].title}}</div>
            <div class="border_content_one">{{images[2].content[0]}}</div>
<!--            <div class="border_content_two">{{images[2].content[1]}}</div>-->
            <div class="border_img_parent">
              <img :src="images[2].img" class="img_border"/>
            </div>


          </div>
        </el-col>
        <el-col :xs="15" :sm="15" :md="8">
          <div class="border">
            <div class="border_title">{{images[3].title}}</div>
            <div class="border_content_one">{{images[3].content[0]}}</div>
<!--            <div class="border_content_two">{{images[3].content[1]}}</div>-->
            <div class="border_img_parent">
              <img :src="images[3].img" class="img_border"/>
            </div>

          </div>
        </el-col>
      </el-row>
    </div>
    <div id="交友广场" class="about_me head-label">
      <div style="text-align: center;" class="font_size_24">
<!--        交友广场-->
      </div>
    </div>
    <div  style="margin: auto;width: 100%;height: 800px;position:relative; ">
      <img style=" width:100%; height: 800px" id="qr-code-img" src="@/assets/images/home/index/index_2.png" alt="#">

      <div style=" position:absolute;top:20%;left:52%;width:100%; height: 700px; font-family: 华文行楷; ">

        <div style="display: flex; flex-direction: column;width: 100%;">
          <p class="about_me_p" style="font-size: 4.5em">生活甜伊点</p>
          <p class="about_me_p"  style=" margin-top: 30px;font-size: 2.5em">分享更多有趣故事</p>
          <p class="about_me_p" style="margin-top: 30px; font-size: 2.5em">遇见更好的Ta</p>
        </div>
      </div>

    </div>


    <div id="联系我们" class="about_me head-label">
      <div style="text-align: center;" class="font_size_24">
<!--                联系我们-->
      </div>
    </div>
    <div  style=" margin: auto;width: 100%;height: 700px;position:relative; ">
      <img style=" width:100%; height: 700px; " id="qr-code-img" src="@/assets/images/home/index/index_3.png" alt="#">


      <div style="position:absolute;top:35%;left:30%;width:100%; height: 700px; ">

              <div style="display: flex; flex-direction: column;width: 100%;">
                <p class="about_me_p">{{company[0]}}</p>
                <p class="about_me_p">{{company[1]}}</p>
                <p class="about_me_p">{{company[2]}}</p>
                <p class="about_me_p">{{company[3]}}</p>
              </div>
      </div>


<!--      <span :style="{backgroundImage:'url('+require('../../assets/image/accounts/dayily.png')+')'}">-->

    </div>

    <!--    &lt;!&ndash; 关于我们&ndash;&gt;-->
<!--    <div id="联系我们" class="about_me head-label">-->
<!--      <div style="text-align: center;" class="font_size_24">-->
<!--        联系我们-->
<!--      </div>-->
<!--    </div>-->
<!--    <div style="width: 90%;background-color: #F2F6FC;margin: 10px auto;">-->
<!--      <div style="display: flex; flex-direction: column;width: 100%;">-->
<!--        <p class="about_me_p">{{company[0]}}</p>-->
<!--        <p class="about_me_p">{{company[1]}}</p>-->
<!--        <p class="about_me_p">{{company[2]}}</p>-->
<!--        <p class="about_me_p">{{company[3]}}</p>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Index',
  data() {
    return {
      sliders: [],
      images: [
        {
          img: require('@/assets/images/home/gw_1.png'),
          title: "用户管理",
          content: ["用户消费分析", "用户标签设定"]
        },
        {
          img: require('@/assets/images/home/gw_1.png'),
          title: "心动推荐",
          content: ["灵魂相遇，邂逅美好"]
        },
        {
          img: require('@/assets/images/home/gw_2.png'),
          title: "聊天互动",
          content: ["在线秒回，主动才有故事"]
        }, {
          img: require('@/assets/images/home/gw_3.png'),
          title: "真人认证",
          content: ["真实可靠，实名审核"]
        }
      ],

      company: [
        "客服Q Q： 3788189203",
        "客服邮箱：3788189203@qq.com",
        "公   司：厦门市盛达光科技有限公司",
        "地   址：福建省厦门市湖里区悦华路151-1号",
      ],
      carousel_height: '550px',
    }
  },
  methods: {},
  mounted() {
    this.sliders = [
      {
        img: require("@/assets/images/home/gw_1.png")
      },
      {
        img: require("@/assets/images/home/gw_2.png")
      },
      {
        img: require('@/assets/images/home/gw_3.png')
      }
    ]
  },
  computed: {
    //计算高度
    computeHeight() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        return '550px';
      } else {
        return '250px';
      }
    },
    computeArrowImg() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 450) {
        return require('@/assets/images/home/gw_1.png');
      } else {
        return require('@/assets/images/home/gw_1.png');
      }
    }

  },
}
</script>

<style scoped>
.border_parent {

  width: 90%;
  margin: auto;
  height: auto;
  justify-content: center;
}

.slider_img {

  height: 550px;
  width: 380px;

}

.solve {
  margin-top: 30px;
  margin-bottom: 10px
}

.border {
  border-radius: 6px;
  border: 1px solid #EBEEF5;
  height: auto;
  margin: 10px;

  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

.border_one {
  margin-left: 0;
}

.border_img_parent {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  height: auto;
}

.product_mobile_content {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product_mobile_img {
  width: 50%;
}

.product_mobile_title {
  font-weight: bold;
  text-align: center;
}

.product_pc_one {
  text-align: center;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.font_size_30 {
  font-size: 1.875em;
}

.product_font_style {
  font-size: 1.125em;
}

.product_pc_img {
  width: 100%
}

.product_pc_img_div {
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
}

.product_pc {
  display: flex;
  width: 90%;
  margin: 20px auto;
  background: #F2F6FC;
  align-items: center;
}

.product_spec_title {
  margin-top: 30px;
  margin-bottom: 10px
}

.product_spec_parent {
  display: flex;
  width: 90%;
  margin: 20px auto;
  align-items: center;
  height: auto
}

.cooperation_parent {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 20px auto;
  align-items: center;
  height: auto
}

.border_spec {
  border-radius: 6px;
  border: 1px solid #EBEEF5;
  float: left;
  width: 25%;
  height: auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.about_me_p {
  font-size: 1.25em;
  font-weight: normal;
  padding: 0 20px;
  margin: 10px;
}

.border_title {
  font-family: "PingFang SC";
  margin-top: 10px;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  color: #EBEEF5;
  //background: #EBEEF5;
}

.border_content_one {
  margin-top: 10px;
  font-size: 1.125em;
  text-align: center;
  color: #EBEEF5;
}

.border_content_two {
  margin-top: 5px;
  font-size: 1.125em;
  margin-bottom: 25px;
  text-align: center;
}

.img_border {
  margin-top: 10px;
  width: 350px;
  height: 700px;

}

.border_spec_inner_one {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  height: 70px;
}

.border_spec_content_one {
  margin-top: 15px;
  font-size: 1.125em;
  float: left;
  text-align: center;
}

.border_spec_inner_last {
  margin-top: 15px;
  font-size: 1.125em;
  float: left;
  margin-bottom: 25px;
  text-align: center;
}

.cooperation {
  margin-top: 30px;
  width: 100%;
}

.about_me {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  width: 100%;
}

.product_mobile {
  width: 90%;
  align-items: center;
  margin: 20px auto;
  background: #F2F6FC;
}

.product_mobile_img_div {
  width: 50%;
  height: auto;
}

.product_mobile_content_one {
  margin-top: 20px;
  text-align: center;
}

.product_mobile_content_two {
  margin-top: 10px;
  text-align: center;
}

/* 屏幕设备适配 max-device-width: 400px */
@media screen and (max-device-width: 415px) {
  .slider_img {
    height: 250px;
    width: 100%;
  }


  .border_parent {
    display: flex;
    width: 90%;
    margin: auto;
    height: auto;
    flex-direction: column;
  }

  .border {
    border-radius: 6px;
    border: 1px solid #EBEEF5;
    float: left;
    width: 100%;
    height: auto;
    margin: 10px 0 auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
    /*background: aquamarine;*/
  }

  .border_img_parent {
    margin-top: 0;

  }

  .product_mobile {
    width: 90%;
    align-items: center;
    margin: 20px auto;
    height: auto;
    background: #F2F6FC;
  }

  .product_mobile_content {
    width: 100%;
    margin-top: 15px;
  }

  .product_mobile_content_one {
    margin-top: 5px;
  }

  .product_mobile_content_two {
    margin-top: 5px;
  }

  .product_mobile_img_div {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .product_mobile_img {
    float: left;
  }

  .product_mobile_title {
    font-weight: bold;
  }

  .product_pc_one {
    width: 100%;
    margin-top: 15px;
  }

  .font_size_30 {
    font-size: 1.25em;
  }

  .product_font_style {
    font-size: 1em;
  }

  .product_pc_img {
    height: 280px;
    width: 320px;
  }

  .product_pc_img_div {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .product_pc {
    display: flex;
    width: 90%;
    height: auto;
    margin: 20px auto;
    background: #F2F6FC;
    flex-direction: column;
  }

  .head-label {
    display: flex;
    margin-top: 10px;
  }

  .product_spec_parent {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    height: auto;
    flex-direction: column;
  }

  .border_spec {
    float: left;
    height: auto;
    margin: 8px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #EBEEF5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }


  .border_title {
    font-family: "PingFang SC";
    margin-top: 5px;
    font-size: 1.125em;
    font-weight: bold;
  }

  .border_content_one {
    margin-top: 5px;
    font-size: 1.125em;
  }

  .border_content_two {
    margin-top: 5px;
    font-size: 1.125em;
    margin-bottom: 8px;
  }

  .img_border {
    margin-top: 8px;
    width: 80px;
    height: 80px
  }

  .border_spec_inner_one {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    /*background-color: aqua;*/
    height: 70px;
  }

  .border_spec_content_one {
    margin-top: 5px;
    font-size: 1.125em;
    float: left;
  }

  .border_spec_inner_last {
    /*background-color: bisque;*/
    margin-top: 5px;
    font-size: 1.125em;
    float: left;
    margin-bottom: 8px;
  }

  .about_me {
    display: flex;
    margin-top: 10px;
    width: 100%;
  }
}

.border_spec_img {
  width: 75px;
  height: 55px;
  align-self: center
}

.font_size_24 {
  font-size: 1.5em;
}
</style>
