<template>

  <div id="app" style="background: #FF6459; ">
    <!--        <div class="header">-->
    <!--&lt;!&ndash;          <div class="logo_img" @click="logoClick">&ndash;&gt;-->
    <!--&lt;!&ndash;            <div style="display:flex; align-items: center; float:left; height: 65px;">&ndash;&gt;-->
    <!--&lt;!&ndash;              <div class="header-logo">&ndash;&gt;-->
    <!--&lt;!&ndash;                <img id="header-img" src="@/assets/logo.png" >&ndash;&gt;-->
    <!--&lt;!&ndash;              </div>&ndash;&gt;-->
    <!--&lt;!&ndash;            </div>&ndash;&gt;-->
    <!--&lt;!&ndash;            <div style="display:flex; float:left; height: 65px;">&ndash;&gt;-->
    <!--&lt;!&ndash;              <div class="header-important">&ndash;&gt;-->
    <!--&lt;!&ndash;                <div class="company_name">甜觅</div>&ndash;&gt;-->
    <!--&lt;!&ndash;                <div class="header-title company_name">遇见美好 甜觅相伴</div>&ndash;&gt;-->
    <!--&lt;!&ndash;              </div>&ndash;&gt;-->
    <!--&lt;!&ndash;            </div>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--&lt;!&ndash;          <div id="menu_index">&ndash;&gt;-->
    <!--&lt;!&ndash;            &lt;!&ndash; web端菜单导航 &ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;            <el-menu v-if="seen" :default-active="activeIndex" class="el-menu-demo" menu-trigger="click" text-color="#333" style="height: 65px; border:0;" mode="horizontal" @select="handleSelect">&ndash;&gt;-->
    <!--&lt;!&ndash;              <el-menu-item index="1" class="header-title">首页</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;              <el-submenu index="2">&ndash;&gt;-->
    <!--&lt;!&ndash;                <template slot="title">产品与解决方案</template>&ndash;&gt;-->
    <!--&lt;!&ndash;                <el-menu-item index="2-1">产品微观</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;                <el-menu-item index="2-2">生鲜果蔬</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;                <el-menu-item index="2-3" disabled>服装品牌</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;                <el-menu-item index="2-4" disabled>微商城小程序</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;              </el-submenu>&ndash;&gt;-->
    <!--&lt;!&ndash;              <el-menu-item index="3" class="header-title">产品特点</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;              <el-menu-item index="4" class="header-title">合作伙伴</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;              <el-menu-item index="5" class="header-title">关于我们</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;            </el-menu>&ndash;&gt;-->
    <!--&lt;!&ndash;            &lt;!&ndash; 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" &ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;            <el-menu v-if="!seen" :default-active="activeIndex" class="el-menu-demo" menu-trigger="click" text-color="#333" style="height: 65px; border:0;" mode="horizontal" @select="handleSelectMobile">&ndash;&gt;-->
    <!--&lt;!&ndash;              <el-submenu index="1" class="header-title mobile_menu">&ndash;&gt;-->
    <!--&lt;!&ndash;                <template slot="title">{{currentMenu}}</template>&ndash;&gt;-->
    <!--&lt;!&ndash;                <el-menu-item index="1" class="header-title">首页</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;                <el-menu-item index="2-2" class="header-title">生鲜果蔬</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;                <el-menu-item index="2-1" class="header-title">产品微观</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;                <el-menu-item index="3" class="header-title">产品特点</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;                <el-menu-item index="4" class="header-title">合作伙伴</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;                <el-menu-item index="5" class="header-title">关于我们</el-menu-item>&ndash;&gt;-->
    <!--&lt;!&ndash;              </el-submenu>&ndash;&gt;-->
    <!--&lt;!&ndash;            </el-menu>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--            <div class="logo_img">-->
    <!--                <div style="display:flex; align-items: center; float:left; height: 100px;">-->
    <!--                    <div class="header-logo">-->
    <!--                        <img id="header-img" src="@/assets/logo.png"  alt="#">-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--&lt;!&ndash;                <div style="display:flex; float:left; height: 100px;">&ndash;&gt;-->
    <!--&lt;!&ndash;                    <div class="header-important">&ndash;&gt;-->
    <!--&lt;!&ndash;                        <div class="company_name">甜觅</div>&ndash;&gt;-->

    <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            </div>-->

    <!--            <div v-if="seen" style="float: right; display: flex;align-items: center; height: 100%;">-->
    <!--                <el type="text" style="margin-right: 20px; font-family: 华文行楷" class="header-title" >甜觅 ｜ 遇见美好 甜觅相伴</el>-->

    <!--            </div>-->

    <!--        </div>-->
    <div class="header">

      <div class="menu_index" background-color="#FF6459"  >
        <!-- web端菜单导航 -->
        <el-menu v-if="seen" :default-active="activeIndex" class="el-menu-demo" menu-trigger="click"
                 text-color="#EBEEF5" background-color="#FF6459"  active-text-color="#EBEEF5"
                 mode="horizontal" @select="handleSelect">
          <el-menu-item index="1" class="header-title" >首页</el-menu-item>
          <el-menu-item index="2" class="header-title">个性化</el-menu-item>
          <el-menu-item index="3" class="header-title">交友广场</el-menu-item>
          <el-menu-item index="4" class="header-title">联系我们</el-menu-item>
        </el-menu>
        <!-- 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" -->
<!--        <el-menu v-if="!seen" :default-active="activeIndex" class="el-menu-demo" menu-trigger="click"-->
<!--                 text-color="#EBEEF5"-->
<!--                 style="height: 65px; border:0;" mode="horizontal" @select="handleSelectMobile">-->
<!--          <el-submenu index="1" class="header-title mobile_menu">-->
<!--            <template>{{ currentMenu }}</template>-->
<!--            <el-menu-item index="1" class="header-title">首页</el-menu-item>-->
<!--            <el-menu-item index="2-2" class="header-title">生鲜果蔬</el-menu-item>-->
<!--            <el-menu-item index="2-1" class="header-title">产品微观</el-menu-item>-->
<!--            <el-menu-item index="3" class="header-title">产品特点</el-menu-item>-->
<!--            <el-menu-item index="4" class="header-title">合作伙伴</el-menu-item>-->
<!--            <el-menu-item index="5" class="header-title">关于我们</el-menu-item>-->
<!--          </el-submenu>-->
<!--        </el-menu>-->
      </div>
      <div class="logo_img" @click="logoClick">
        <div class="logo_img_left">
          <div class="header-logo">
            <img id="header-img" src="@/assets/logo.png" alt="logo">
          </div>
        </div>
        <div class="logo_img_right">
          <div class="header-important">
            <div class="company_name">甜伊点 ｜ 让生活甜伊点</div>
          </div>
        </div>
      </div>

    </div>
    <div class="container" style="clear: both;height: 100%;">
      <router-view></router-view>
    </div>

    <div style="width: 90%;margin: 20px auto;">
      <div style="height:1px;padding:0;background-color:#999;">
      </div>
    </div>
    <div style="width: 90%;margin: 20px auto;text-align: center;color: #EBEEF5;">
      <el-row :gutter="24">
        <el-col :xs="24" :sm="24">

          <div style="margin: auto;text-align: center;" class="footer_font">
            <el-link href="http://tianmi-res.xmsdg.top/protocol/yhxy.html" target="_blank" style="color: #EBEEF5;font-size: 1.0em;">用户协议</el-link>
            |
            <el-link href="http://tianmi-res.xmsdg.top/protocol/yszc.html" target="_blank" style="color: #EBEEF5;font-size: 1.0em;">隐私协议</el-link>
            |
            <el-link href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #EBEEF5;font-size: 1.0em;">
              闽ICP备2023020819号
            </el-link>
          </div>
          <div style="margin: auto; color: #EBEEF5;margin-bottom: 10px;" class="footer_font">
            Copyright © 2024 厦门盛达光科技有限公司版权所有
          </div>
        </el-col>
        <!--                <el-col :xs="24" :sm="12">-->
        <!--                    <div><img :src="company.qrcode" style="width: 100px;height: 100px;border: 0"/></div>-->
        <!--                    <div class="footer_font">关注坚冰商务公众号</div>-->
        <!--                </el-col>-->
      </el-row>
    </div>
    <div id="back_to_top" ref="btn" @click="backTop" style="display: none;">
      <p style="font-size: 0.625em; font-weight: bold">TOP</p>
      <img src="@/assets/images/launch.png" style="height: 45px;width: 35px" alt="#"/>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Layout",
  data() {
    return {
      defaultActive: "Index",
      activeIndex: '1',
      scroll: '',
      timer: '',
      seen: true,
      currentMenu: '首页',
    };
  },
  created () {
    this.defaultActive = this.$router.push({name: "Index"},()=>{},()=>{}) // 关键 通过他就可以监听到当前路由状态并激活当前菜单
  },
  methods: {
    handleSelect(key) {
      switch (key) {
        case '1':
          this.$router.push({name: "Index"},()=>{},()=>{});
          break;
        case '2':
          this.$router.push({name: "Index"},()=>{},()=>{});
          window.location.hash = "#个性化";
          break;
        case '3':
          this.$router.push({name: "Index"},()=>{},()=>{});
          window.location.hash = "#交友广场";
          break;
        case '4':
          this.$router.push({name: "Index"},()=>{},()=>{});
          window.location.hash = "#联系我们";
          break;
      }
    },
    handleSelectMobile(key) {
      switch (key) {
        case '1':
          this.$router.push({name: "Index"});
          break;
        case '2':
          this.$router.push({name: "Index"});
          window.location.hash = "#产品特点";
          break;
        case '3':
          this.$router.push({name: "Index"});
          window.location.hash = "#合作伙伴";
          break;
        case '4':
          this.$router.push({name: "Index"});
          window.location.hash = "#联系我们";
          break;
      }
    },
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 600) {  //判断滚动后高度超过400px,就显示
        this.$refs.btn.style.display = 'block';
      } else {
        this.$refs.btn.style.display = 'none'
      }
    },
    backTop() { //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
      this.timer = setInterval(() => {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        if (osTop === 0) {
          clearInterval(this.timer)
        }
      }, 30)
    },

    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    logoClick() {
      this.$router.push({name: "Index"},()=>{},()=>{});
      this.currentMenu = '首页';
    }
  },
  mounted() {
    this.menuSeen()
    window.addEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped>
.header {
  margin: auto;
  width: 90%;
  height: 100px;

}

.logo_img {
  float: right;
  width: 45%;
  height: 100%;
  cursor: pointer;
  margin-left: 3%;
}

.logo_img_left {
  display: flex;
  align-items: center;
  float: left;
  height: 100%;
}

.header-logo {
  height: 60px;
  width: 60px;
  align-self: center;
  margin-left: 3%;
}

#header-img {
  width: 60px;
  height: 60px;
  float: left;
  margin: auto;
}

.logo_img_right {
  display: flex;
  float: left;
  height: 100%;
}

.header-important {
  float: left;
  margin-left: 6px;
  align-self: center;
}

.company_name {
  font-weight: normal;
  font-size: 2.3em;
  font-family: 华文行楷;
  color: #EBEEF5;
}

.menu_index {
  //border:1px black solid ;
  float: left;
  width: 43%;
  height: 100%;
  margin-left: 20px;
  background-color: #FF6459;
  align-self: center;
  color: #EBEEF5;

  box-shadow: none;
}

.el-menu-demo {
  border: 0;
  height: 100%;
  margin-right: 20px;
  background: #FF6459;
  align-self: center;
  color: #EBEEF5;

}

.header-title {
  margin-top: 12px;
  font-size: 1.25em;
  color: #EBEEF5;
  background: #FF6459;
}


#back_to_top {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;

}


.footer_font {
  font-size: 1em;
}


@media screen and (max-device-width: 415px) {
  .logo_img {
    float: left;
    width: auto;
    height: auto;
    cursor: pointer;
  }

  #back_to_top {
    position: fixed;
    bottom: 50px;
    right: 5px;
    cursor: pointer
  }

  .footer_font {
    font-size: 0.875em;
  }

  .menu_index {
    //border:1px black solid ;
    float: left;
    width: 33%;
    height: 100%;
    margin-left: 20px;
    background: #FF6459;
    align-self: center;
    color: #EBEEF5;

    box-shadow:0 0px 0px;
  }

  .mobile_menu {

  }
}




a {
  text-decoration: none;
  box-shadow:none;
  background-color: #FF6459;
  color: #FF6459;
}

a:visited {
  text-decoration: none;
  box-shadow:none;
  background-color: #FF6459;
  color: #FF6459;
}

a:hover {
  text-decoration: none;
  box-shadow:none;
  background-color: #FF6459;
  color: #FF6459;
}

a:active {
  text-decoration: none;
  box-shadow:none;
  background-color: #FF6459;
  color: #FF6459;
}

.el-menu-item:hover {
  background-color: transparent !important;
}

</style>
